import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
  Alert,
  Table,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, height, width } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import Assigned from "../../../../../../../designUtils/Storeoverview/status/Assigned.svg"
import AmazonTicketRaised from "../../../../../../../designUtils/Storeoverview/status/AmazonTicketRaised.svg"
import AmazonResponseReceived from "../../../../../../../designUtils/Storeoverview/status/AmazonResponseReceived.svg"
import Closed from "../../../../../../../designUtils/Storeoverview/status/Closed.svg"
import NeedInfo from "../../../../../../../designUtils/Storeoverview/status/NeedInfo.svg"
import Open from "../../../../../../../designUtils/Storeoverview/status/Open.svg"
import Resolved from "../../../../../../../designUtils/Storeoverview/status/Resolved.svg"
import Updated from "../../../../../../../designUtils/Storeoverview/status/Updated.svg"
import Pending from "../../../../../../../designUtils/Storeoverview/status/Pending.svg"
import InProgress from "../../../../../../../designUtils/Storeoverview/status/InProgress.svg"
import { faEye, faDownload, faFilePdf,faFileExcel,faFileWord,faLeaf,faPaperclip } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor, SetInnerHtmlWithClassReplacement } from "../../../../../../../Utils/Constants";
import OPSAddNewCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSAddNewCommentAttachmentService";
import OPSGetCommentAttachmentService from "../../../../../../../Service/OPSServices/OPSTicketServices/OPSGetCommentAttachmentService";
function OpsDashViewTicket(props) {
  const [ticketHistory, setTicketHistory] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [text, setText] = useState("");
  const [amazonCaseId, setAmazonCaseId] = useState(props.amazonCaseId ? props.amazonCaseId : '');
  const [amazonCaseUrl, setAmazonCaseUrl] = useState((props.amazonCaseUrl !='#' &&  props.amazonCaseUrl!='' ) ? props.amazonCaseUrl : "");
  const [tempAmazonCaseId, setTempAmazonCaseId] = useState(props.amazonCaseId ? props.amazonCaseId : '');
  const [tempAmazonCaseUrl, setTempAmazonCaseUrl] = useState((props.amazonCaseUrl !='#' &&  props.amazonCaseUrl!='' )? props.amazonCaseUrl : "");
  const [errors, setErrors] = useState({ tempAmazonCaseUrl: "" });
  const [ticketHistoryText, setTicketHistoryText] = useState("");
  const [ticketHistoryUpdatedBy, setTicketHistoryUpdatedBy] = useState("");
  const [ticketHistoryComments, setTicketHistoryComments] = useState();
  const [isTicketHistoryTextOpen, setIsTicketHistoryTextOpen] = useState(false);
  const [isExpandedHistory, setIsExpandedHistory] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const [accessLevel,setAccessLevel] = useState();
  const [warningpopupOpen, setwarningpopupOpen] = useState(false);
  const [yesButton, setyesButton] = useState(false);
  const [amazonButtonDisable, setAmazonButtonDisable]= useState(false)
  const [loggedInUser, setLoggedInUser] = useState({ userId: '', accepted: false })
  const [allFiles,setAllFiles]=useState([])
  const [ filePoup,setFilePoup] =useState(false)
  const [ popupFilePath, setPopupFilePath]=useState('')
  const [temp, setTemp] = useState(0);
  const [createrPriority, setCreaterPriority] = useState('');
  const handleYesAction = () => {
    console.log("Performing Yes action...");
    setyesButton(true)
    if(temp === 1){
      handleUpload('Amazon Response Received','','',true)
    }
    if(temp === 2){
      handleUpload('Resolved','','',true)
    }
    if(temp === 3){
      handleUpload('Informed','','',true)
    }
    if(temp === 4){
      handleUpload('Need info','','',true)
    }
    if(temp === 5)
    {
      saveData(tempAmazonCaseId,tempAmazonCaseUrl,1,true)
    }
    setwarningpopupOpen(false)
  };
  const [latestAttachmets,setLatestAttachmets]=useState([])
  const [maxLengthComments,setMaxLengthComments]=useState()
  const handleNoAction = () => {
    console.log("Performing No action...");
    if(temp === 1){
      handleUpload('Amazon Response Received','','',false);
    }
    if(temp === 2){
      handleUpload('Resolved','','',false);
    }
    if(temp === 3){
      handleUpload('Informed','','',false);
    }
    if(temp === 4){
      handleUpload('Need info','','',false);
    }
    if(temp === 5)
    {
      saveData(tempAmazonCaseId,tempAmazonCaseUrl,1,false)
    }
    if(temp === 5){
      setOpenAmazonDetails(false)
    }
    setwarningpopupOpen(false)
  };
  

  const [status, setStatus] = useState({
    ticketStatus: "",
  });
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState({
    ticketTitle: "",
    ticketRequestType: "",
    ticketDate: "",
    ticketStatus: "",
    issueDescription: '',
    ticketSubRequestType: "",
    Asin: "",
    assignedToID:'',
    Sku: "",
    problemAreaId: "",
    problemAreaTitle: "",
    problemAreaPathwayId: "",
    problemAreaPathwayTitle: "",
    priority: '',
    amazonCaseId: '',
    amazonCaseUrl: ''
  });
  const [openAmazonDetails, setOpenAmazonDetails] = useState(false);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [numericIdMsg, setNumericIdMsg] = useState(false)
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const setTicketInfo = () => {
    if (props.ticketList && props.ticketId) {
      var requestMainType = "";
      props.ticketList.map((ticket) => {
        {
          if (ticket.ticketId === props.ticketId) {
            props.mainRequestTypes.map((Type) => {
              {
                if (Type.id === ticket.mainRequestTypeId) {
                  let asinArray = [];
                  let problemAreaPathwayTitle = "";
                  if (props.problemAreaPathwayList) {
                    props.problemAreaPathwayList.map((pap) => {
                      if (props.problemAreaPathwayId == pap.id) {
                        problemAreaPathwayTitle = pap.problemAreaPathway;
                      }
                    });
                  }
                  // if (ticket.Asin.length > 0) {
                  //   asinArray = ticket.Asin[0].split(",");
                  // }

                  //console.log(asinArray);
                  setState({
                    ticketTitle: ticket.ticketTitle,
                    ticketDate: ticket.ticketCreatedAt,
                    ticketStatus: ticket.ticketStatus,
                    issueDescription: ticket.issueDescription,
                    ticketRequestType: Type.requestType,
                    ticketSubRequestType: ticket.requestSubTypes,
                    // Asin: asinArray, //ticket.Asin,
                    assignedToID:ticket.assignedToID,
                    Sku: ticket.Sku,
                    problemAreaPathwayTitle: ticket.problemAreaPathway,
                    priority: ticket.priority,
                    amazonCaseId: ticket.amazonCaseId,
                    amazonCaseUrl: ticket.amazonCaseUrl
                  });
                }
              }
            });
          }
          // if (ticket.ticketId === props.ticketId) {
          //   props.ticketViewData.map((history) => {
          //     {
          //       if (history.ticketId === props.ticketId) {
          //         setTicketHistory((prevTicketHistory) => [
          //           ...prevTicketHistory,
          //           history,
          //         ]);
          //       }
          //     }
          //   });
          // }
        }
      });
    }
  };
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };
  const validateUrl = (url) => {
    const urlPattern = /^(https?:\/\/)/i;
    return urlPattern.test(url);
  };
  const saveData = (tempAmazonCaseId,tempAmazonCaseUrl,flag,action) =>{
    setAmazonButtonDisable(true)
    console.log('111111111');
    console.log(flag);
    let ticketStatus = ''
    if(flag == 1)
    {
      ticketStatus='Amazon Ticket Raised'
    }
    if(flag == 2)
    {
      ticketStatus = 'Amazon Response Received'
    }
    // return true
    setAmazonCaseId(tempAmazonCaseId)
    setAmazonCaseUrl(tempAmazonCaseUrl)
    handleUpload(ticketStatus,tempAmazonCaseId,tempAmazonCaseUrl,action)
  }
  const handleTextChange = (e) => {

    const { name, value } = e.target;
    let newErrors = { ...errors };
    if (name === 'tempAmazonCaseUrl') {
      const isValid = validateUrl(value);
      setIsValidUrl(isValid);
      if (!isValid && value.length > 0) {
        newErrors[name] = 'Please enter a valid URL starting with http:// or https://';
      } else {
        newErrors[name] = '';
      }
      setTempAmazonCaseUrl(value)
    }
    else if (name === 'tempAmazonCaseId') {
      if (/^\d*$/.test(value)) {
        setTempAmazonCaseId(value)
        newErrors[name] = '';
        setNumericIdMsg(false)
      }
      else {
        return setNumericIdMsg('Please enter only numbers')
      }
    }
    else {
      setText(e.target.value);
    }
    setErrors(newErrors);
  };
  const handleQuillChange = (value) => {
    setText(value);
  };
  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setStatus({ ...status, [name]: value });
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    const data = {
      ticketId: props.ticketId
    }
    OPSGetCommentAttachmentService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
        setAccessLevel(response.ticketHistory[0].accessLevel)
        setLoggedInUser(response.loggedInUser)
        setTicketHistoryComments(response.ticketHistory[0].ticketHistoryComment)
        setCreaterPriority(response.ticketHistory[0].createrPriority)
        setLatestAttachmets(response.ticketHistory[0].ticketAttachment)
        console.log(response.ticketHistory[0].ticketAttachment)
        if (response.ticketHistory) {
          setTicketHistory(response.ticketHistory)
        }

        if (response.productList) {
          setProductList(response.productList)
        }
        // props.handleCreateAndViewTicketClose(response);
      }
    });
  }, [])
  useEffect(() => {
    setTicketInfo();
  }, []);

  const filteredTicketHistory = ticketHistory.filter((row) => {
    const historyComment = row.ticketHistoryComment.toLowerCase();
    const ticketHistoryCreateBy = row.ticketHistoryCreateBy.toLowerCase();
    const ticketHistoryStatus = row.ticketHistoryStatus.toLowerCase();
    return historyComment.includes(searchText.toLowerCase()) || ticketHistoryCreateBy.includes(searchText.toLowerCase()) || ticketHistoryStatus.includes(searchText.toLowerCase());
  });


  const handleUpload = async (flag,id,url,action) => {
    if (state.ticketStatus === 'Closed') {
      return true
    }
    const formData = new FormData();
    console.log(state)
    if (props.accessLevel == 0) {
    
      if (flag == 'Need info') {
        setLoading1(true)
        formData.append("ticketStatus", flag);
      }
      else if (flag == 'Informed') {
        setLoading2(true)
        formData.append("ticketStatus", flag);
      }
      else if (flag == 'Resolved') {
        setLoading3(true)
        formData.append("ticketStatus", flag);
      }
      else if (flag == 'Amazon Ticket Raised') {
        formData.append("ticketHistoryComment",'<p>Amazon Case has been raised for this ticket.</p> <p> &nbsp; Amazon Case Id : ' + id +' </p> <p>&nbsp; URL : '+url +' </p>')
        formData.append("ticketStatus", 'Amazon Ticket Raised');
      }
      else if (flag == 'Amazon Response Received') {
        setLoading4(true)
        formData.append("ticketStatus", 'Amazon Response Received');
      }
      else {
        formData.append("ticketStatus", status.ticketStatus);
      }
    } else if (props.accessLevel != 0 && flag == 1) {
      formData.append("ticketStatus", 1);
    } else {
      formData.append("ticketStatus", 0);
    }
    if(flag == 'Amazon Ticket Raised')
    {
      formData.append("amazonCaseId", id);
      formData.append("amazonCaseUrl", url);
    }
    else{
      formData.append("amazonCaseId", amazonCaseId);
      formData.append("amazonCaseUrl", amazonCaseUrl);
    }
    if(flag != 'Amazon Ticket Raised')
    {
      formData.append("ticketHistoryComment", text);
    }
    formData.append("ticketId", props.ticketId);
    formData.append("storeName", props.storeNameForViewTicket);
    formData.append("integerId", props.storeIdForViewTicket);
    formData.append('action',action)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    OPSAddNewCommentAttachmentService(props.token, formData).then(
      (response) => {
        if (response) {
          console.log(response);
          console.log(response.allTicketList);
          if (flag == 2) {
            setOpenAmazonDetails(false)           
            setAmazonCaseId(response.amazonCaseId)
            setAmazonCaseUrl(response.amazonCaseUrl)
            setTempAmazonCaseId(response.amazonCaseId)
            setTempAmazonCaseUrl(response.amazonCaseUrl)
            props.handleAmazonCasePopup(response.allTicketList);          }
          else {
            setText("");
            setSelectedFiles(null);
            if(props.isFromCreatingTicket == false)
              { props.handleCreateAndViewTicketClose(response.allTicketList);}
            else{
              props.handleCreateTicketPopup(response);
            }
            // props.handleCreateAndViewTicketClose(response.allTicketList, props.);
          }
          setLoading1(false);
          setLoading2(false);
          setLoading3(false);
          setLoading4(false);
          setAmazonButtonDisable(false)
        }
      }
    );
  };
   const show = (path) => {
    const separatedPath = path.split("/");
    const ext = separatedPath[1].split(".")[1];
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
  const viewDownloadFile = async (file) => {
    console.log(file)
    try {
      // Extract file name without extension
      let fileName = file.ticketAttachment.split('/').pop(); // Get the last part of the path
      fileName = fileName.split('.')[0]; // Remove the file extension
  
      // Fetch the file from the URL
      const fileUrl = file.ticketAttachment;
      const response = await fetch(fileUrl);
  
      if (!response.ok) {
        throw new Error(`Network response was not ok for file ID: ${file.ticketAttachmentId}`);
      }
  
      // Create a blob from the response
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = fileName || 'downloaded_file'; // Use extracted file name or fallback
      document.body.appendChild(downloadLink);
  
      // Trigger the download
      downloadLink.click();
  
      // Cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
  
      console.log(`File downloaded successfully: ${fileName}`);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };
  
  
  const downloadFile = async (file) => {
    console.log(file)
    if (true) {
      try {
        let fileName = file.ticketAttachment.split('/')[1]
        fileName = fileName.split('.')[0]
        const response = await fetch("https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" + file.ticketAttachment);
        if (!response.ok) throw new Error(`Network response was not ok for ${file.ticketAttachmentId}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'downloaded_file';
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Failed to download files:', error);
      }
    }
  }
  const viewSingleHistory = (text, updatedBy) => {
    setTicketHistoryText(text);
    setTicketHistoryUpdatedBy(updatedBy);
    setIsTicketHistoryTextOpen(true);
  };
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const isImage = (fileName) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  };
  const isPdfFile =(fileName)=>{
    const imageExtensions = ["pdf"];
    const extension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }
  const isExcelFile =(fileName)=>{
    const imageExtensions = ["xlsx","xls","csv"];
    const extension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }
  const replaceticketHistoryText = SetInnerHtmlWithClassReplacement(ticketHistoryText?ticketHistoryText:'');
  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(state.issueDescription);
  const replaceTicketHistoryComments = SetInnerHtmlWithClassReplacement(ticketHistoryComments?ticketHistoryComments:'');
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
  const columns = [
    {
      Header: "Status",
      width:80,
      accessor: "ticketHistoryStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "btn btn-sm text-primary "
                : cellInfo.value === 'New'
                  ? "btn btn-sm text-danger"
                  : cellInfo.value === "Resolved"
                    ? "btn btn-sm text-success"
                    : cellInfo.value === "Need info"
                      ? "btn btn-sm text-info "
                      : cellInfo.value === "Informed"
                        ? "btn btn-sm text-secondary "
                        : cellInfo.value === "Ticket updated"
                          ? "btn btn-sm text-warning"
                          : cellInfo.value === "Assigned"
                            ? "btn btn-sm text-warning"
                            : "btn btn-sm"
            }
          >
            {cellInfo.value}
          </span>
        </div>
      ),
    },
    {
      Header: "Comments",
      width:220,
      accessor: "ticketHistoryComment",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
            // {  viewSingleHistory(
            //   cellInfo.value, 
            //   cellInfo.original.ticketHistoryCreateBy);setAllFiles(cellInfo.original.ticketAttachment)}
            // }
            {setAllFiles(cellInfo.original.ticketAttachment);
              viewSingleHistory(
                cellInfo.value,
                cellInfo.original.ticketHistoryCreateBy)}
              }
          >
            {stripHtmlTags(cellInfo.value)}
          </p >
          {cellInfo.original.ticketAttachment.length > 0?<p className="mt-1" style={{fontSize:'15px'}}> 
             <FontAwesomeIcon
                className="mr-2"
                icon={faPaperclip}
                onClick={() => {setAllFiles(cellInfo.original.ticketAttachment);
                  viewSingleHistory(
                    cellInfo.value,
                    cellInfo.original.ticketHistoryCreateBy)}}
            /></p>:''}
        </div>
      ),
    },
    {
      Header: "Updated by",
      width:100,
      accessor: "ticketHistoryCreateBy",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Date",
      width:120,
      accessor: "ticketHistoryCreatedAtOriginal",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.original.ticketHistoryCreatedAt}</p>
        </div>
      ),
    },



    // {
    //   Header: "Attachment",
    //   accessor: "ticketAttachment",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       {/* <a className="m-0" href={cellInfo.value} target="_blank">
    //       {cellInfo.value}
    //       </a> */}
    //       <Button  className='btn_class' onClick={()=>show(cellInfo.value)}> view</Button>
    //     </div>
    //   ),
    // },
  ];
  const handleOpenAmazonDetails = () => {
    if(props.permissions)
      {
        if(!props.permissions.permissions.PartnerCommunication_AmazonCaseRaised)
        {
          return false
        }
      }
    if(loggedInUser.userId === '')
      {
        return true
      }
      if(state.ticketStatus === "Closed"){
        return true
      }
    setOpenAmazonDetails(true)
  }
  const ReadMorelatestComments = ({ htmlContent, maxLength }) => {

    const toggleReadMore = () => {
      setIsExpandedHistory(!isExpandedHistory)
    };

    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div className="">
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{fontSize:'13px'}} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };
  const ReadMore = ({ htmlContent, maxLength }) => {

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };
setMaxLengthComments(htmlContent.length)

    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div className="">
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{fontSize:'13px'}} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };
function stripHtml(htmlString) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
}
const showImg=(file)=>{
  console.log(file)
  setFilePoup(true)
  // setFilePath(file)
  setPopupFilePath(file)
}
  return (
    <Container fluid className="">
      <Card className="p-3 card-of-problemarea">
        <Row className="m-0 justify-content-center" style={{ fontSize: "19px" }}>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <Label htmlFor="subRequestType">
                  <b>Products{productList ? `(${productList.length}) :` : ''}</b>
                </Label>
                <div
                  className={
                    productList.length > 1
                      ? "scrollbar "
                      : " "
                  }
                >
                  {productList.length > 0 ? (
                    productList.map((product) =>
                      <>
                        <Row className="border row-background-color justify-content-center p-0 m-0">
                          {/* <Col md="2" className="p-2">
                            <div>
                              <img
                                className="ml-0"
                                width={"100%"}
                                src={product.itemImage}
                              />
                            </div>
                          </Col> */}
                          <Col md="12" className="p-2">
                            <div>
                              <h6>
                                <b>{product.asin} :</b> &nbsp;
                                <a href={product.link} className="text-decoration-none " target="blank">{product.productTitle} </a>
                              </h6>


                              {/* <Col md={7}>
                                    <div>
                                                      <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                                    </div> 
                                  </Col>*/}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                  ) : (
                    <p className="text-center"> No product selected .</p>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <Label><b>Creater Priority : </b>{createrPriority}</Label>
                </Col>
              <Col md={12} >
              <Label for="Created Date">
                    <b>Issue Description : </b>
                  </Label>
                <div className='custom-issue-dis-box '>
                 
                  <ReadMore htmlContent={replaceIssueDescriptionText} maxLength={200} />
                </div>
                {/* <p>{state.issueDescription}</p> */}
                {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} /> */}
              </Col>
              <Col md="12" className="mbg-3 mt-2 font-size-lg p-0">
                <div className="ml-3 mr-3">
                  <h5><b>Ticket History</b></h5>

                  <div >
                    <input
                      type="text"
                      className="form-control w-50 mb-3"
                      placeholder="Search Ticket History"
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <ReactTable
                    data={filteredTicketHistory}
                    columns={columns}
                    className="-fixed -highlight -striped"
                    getTheadProps={() => {
                      return {
                        style: {
                          overflowY: "hidden",
                          background: "#B1FA63",
                          color: "#243837",
                        },
                      };
                    }}
                    defaultPageSize={5}
                    noDataText={"No Data Found."}
                    SubComponent={(row) => (
                      <div className="p-1">
                        {console.log(row.original.ticketAttachment)}
                        {row.original.ticketAttachment.length > 0 ? (
                          <ReactTable
                            data={row.original.ticketAttachment}
                            columns={[
                              {
                                Header: "Name",
                                width:450,
                                accessor: "ticketAttachment",
                                Cell: (cellInfo) => (
                                  <div className="d-flex justify-content-center  w-100">
                                    <p className="comment-paragraph-ticket-history" title={cellInfo.value.split("/")[1]}>
                                    {cellInfo.value.split("/")[1]}
                                    </p>
                                  </div>
                                ),
                              },
                              {
                                Header: "Action",
                                width:80,
                                accessor: "ticketAttachment",
                                Cell: (cellInfo) => (
                                  <div className="d-flex justify-content-center w-100">
                                    {
                                      fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                        <FontAwesomeIcon
                                          className="ml-3"
                                          style={{ cursor: 'pointer' }}
                                          icon={faDownload}
                                          onClick={() => downloadFile(cellInfo.original)}
                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon
                                            className="ml-3"
                                            style={{ cursor: 'pointer' }}
                                            icon={faEye}
                                            onClick={() => show(cellInfo.value)}
                                          />
                                          <FontAwesomeIcon
                                            className="ml-3"
                                            style={{ cursor: 'pointer' }}
                                            icon={faDownload}
                                            onClick={() => downloadFile(cellInfo.original)}
                                          />
                                        </>
                                      )
                                    }

                                  </div>
                                ),
                              },
                            ]}
                            getTheadProps={() => {
                              return {
                                style: {
                                  overflowY: "hidden",
                                  background: "#B1FA63",
                                  color: "#243837",
                                },
                              };
                            }}
                            className="-striped -highlight"
                            defaultPageSize={row.original.ticketAttachment.length}
                            noDataText="No Attachments available"
                          />
                        ) : (
                          <p className="text center">No Attachments.</p>
                        )}
                      </div>
                    )}
                  />
                </div>

              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={5} >
                 {props.permissions?
                  ( props.permissions.permissions.PartnerCommunication_AmazonCaseRaised && 
                    <Label
                    onClick={handleOpenAmazonDetails}
                    className="cursor-pointer mb-0  ml-3">
                    <b>Amazon Case Id : </b>
                    <span>{amazonCaseId ? <a href={amazonCaseUrl} target='_blank'>{amazonCaseId}</a> : <a href={props.amazonCaseUrl} target='_blank'>{props.amazonCaseId}</a>}</span>
                  </Label>
                  ):props.UserPermission?(props.UserPermission.PartnerCommunication_AmazonCaseRaised && 
                    <Label
                    onClick={handleOpenAmazonDetails}
                    className="cursor-pointer mb-0  ml-3">
                    <b>Amazon Case Id : </b>
                    <span>{amazonCaseId ? <a href={amazonCaseUrl} target='_blank'>{amazonCaseId}</a> : <a href={props.amazonCaseUrl} target='_blank'>{props.amazonCaseId}</a>}</span>
                  </Label>
                     ):""}
              </Col>
              <Col md={7} >
                <Label for="Status">

                  <div className='img-class-button pb-0   pt-0 d-flex justify-content-end'>
                    <p className={state.ticketStatus === 'New' ? 'Open-button pb-0 pt-0' :
                      state.ticketStatus === "Closed" ? 'closed-button  pb-0 pt-0' :
                      state.ticketStatus === "Amazon Ticket Raised" ? 'amazon-ticket-raised  pb-0 pt-0' :
                      state.ticketStatus === "Amazon Response Received" ? 'amazon-response-received  pb-0 pt-0' :
                        state.ticketStatus === "Resolved" ? 'Resolved-button pb-0 pt-0' :
                          state.ticketStatus === "Need info" ? 'Needinfo-button pb-0 pt-0' :
                            state.ticketStatus === "Informed" ? 'Inprogress-button pb-0 pt-0' :
                              state.ticketStatus === "Ticket Updated" ? 'Ticketupdat-button pb-0 pt-0' :
                                state.ticketStatus === "Assigned" ? 'Assigned-button pb-0 pt-0' :
                                  state.ticketStatus === "Pending" ? 'Pending-button pb-0 pt-0' : ''}>
                      {state.ticketStatus === "Closed" ? <img className="img-class" src={Closed} /> :
                        state.ticketStatus === 'New' ? <img className="img-class" src={Open} /> :
                        state.ticketStatus === 'Amazon Ticket Raised' ? <img className="img-class" src={AmazonTicketRaised} /> :
                        state.ticketStatus === 'Amazon Response Received' ? <img className="img-class" src={AmazonResponseReceived} /> :
                          state.ticketStatus === "Resolved" ? <img className="img-class" src={Resolved} /> :
                            state.ticketStatus === "Informed" ? <img className="img-class" src={InProgress} /> :
                              state.ticketStatus === "Ticket Updated" ? <img className="img-class" src={Updated} /> :
                                state.ticketStatus === "Assigned" ? <img className="img-class" src={Assigned} /> :
                                  state.ticketStatus === "Pending" ? <img className='img-class' src={Pending} /> :
                                    state.ticketStatus === "Need info" ? <img className="img-class" src={NeedInfo} /> :
                                      ''} <span style={{ color: 'white' }} className="mt-2">{state.ticketStatus}</span>
                    </p>
                  </div>
                </Label>
              </Col>
              <Col md={12} className='mb-3'>
                <div className="d-flex justify-content-between align-items-center">
                <Label for="Created Date" className='ml-3'>
                  <b>Internal Priority :</b>&nbsp; {state.priority}
                </Label>
                  <div className="d-flex justify-content-end">
                    {state.ticketStatus !== "Closed" && (
                      <>
                      {
                     amazonCaseId && (props.permissions?props.permissions.permissions.PartnerCommunication_AmazonResponseReceived:false) ?
                        <Button
                          className="btn_class ml-2"
                          size="lg"
                          // onClick={() => handleUpload('Amazon Response Received')}
                          onClick={() => {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Amazon Response Received'));setTemp(1);}}
                          disabled={!stripHtml(text)? true :(state.ticketStatus === "Closed" || loading4) ? true : false}
                        >
                          {loading4 ? "Updating..." : "Amazon Response Received"}
                        </Button>
                        :''
                      }
                       
                       {props.permissions?
                         ( props.permissions.permissions.PartnerCommunication_MarkResolve && <Button
                              className="btn_class float-right ml-2"
                              size="lg"
                              onClick={() => {((state.assignedToID != loggedInUser.userId)  && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Resolved'));setTemp(2);}}
                              disabled={!stripHtml(text) ? true : (loading3 ? true : false)}
                            >
                              {loading3 ? 'Updating...' : 'Mark Resolve'}

                            </Button>):props.UserPermission?(props.UserPermission.PartnerCommunication_MarkResolve &&  <Button
                               className="btn_class float-right ml-2"
                               size="lg"
                               onClick={() => {((state.assignedToID != loggedInUser.userId)  && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Resolved'));setTemp(2);}}
                               disabled={!stripHtml(text) ? true : (loading3 ? true : false)}
                             >
                               {loading3 ? 'Updating...' : 'Mark Resolve'}
 
                             </Button>
                            ):""}

                        {props.permissions?props.permissions.permissions.PartnerCommunication_Inform &&  (<Button
                          className="btn_class float-right ml-2"
                          size="lg"
                          // onClick={() => handleUpload('Informed')}
                          onClick={() => {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Informed'));setTemp(3);}}
                          disabled={(state.ticketStatus == "Closed") || !stripHtml(text) ? true : (loading2 ? true : false)}
                        >
                          {loading2 ? 'Updating...' : 'Inform'}

                        </Button>):props.UserPermission?(props.UserPermission.PartnerCommunication_Inform  && <Button
                          className="btn_class float-right ml-2"
                          size="lg"
                          // onClick={() => handleUpload('Informed')}
                          onClick={() => {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Informed'));setTemp(3);}}
                          disabled={(state.ticketStatus == "Closed") || !stripHtml(text) ? true : (loading2 ? true : false)}
                        >
                          {loading2 ? 'Updating...' : 'Inform'}

                        </Button>
                        ):""}
                        

                        {props.permissions?(props.permissions.permissions.PartnerCommunication_NeedInfo && <Button
                          className="btn_class float-right ml-2"
                          size="lg"
                          // onClick={() => handleUpload('Need info')}
                          onClick={() => {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Need info'));setTemp(4);}}
                          disabled={(state.ticketStatus == "Closed") || !stripHtml(text) ? true : (loading1 ? true : false)}
                        >
                          {loading1 ? 'Updating...' : 'Need Info'}

                        </Button>):props.UserPermission?(props.UserPermission.PartnerCommunication_NeedInfo  && <Button
                          className="btn_class float-right ml-2"
                          size="lg"
                          // onClick={() => handleUpload('Need info')}
                          onClick={() => {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ?setwarningpopupOpen(true):handleUpload('Need info'));setTemp(4);}}
                          disabled={(state.ticketStatus == "Closed") || !stripHtml(text) ? true : (loading1 ? true : false)}
                        >
                          {loading1 ? 'Updating...' : 'Need Info'}

                        </Button>
                        ):""}
                       
                      </>
                    )}
                    {/* {state.ticketStatus === "Resolved" &&
                      props.accessLevel !== 0 ? (
                        <Button
                          className="btn_class float-right mr-2"
                          size="lg"
                          onClick={() => handleUpload(1)}
                          disabled={!text ? true :( loading ? true : false) }
                        >
                          {loading ? 'Closing...' : 'Close'}
                          
                        </Button>
                      ) : (
                        ""
                      )} */}
                  </div>
                </div>
                <p className="text-center mt-2">
                  {state.ticketStatus === "Closed" && (
                    <Alert>Note: This ticket is closed.</Alert>
                  )}
                  {/* {state.ticketStatus !== 'Closed' && (
                        <Button
                          className="btn_class"
                          size="lg"
                          onClick={handleUpload}
                        >
                          Save
                        </Button>
                      )} */}
                </p>

              </Col>
              <Col md={12} className='mt-3 mb-2'>
              <Label className='ml-3'><b>Latest Comment :</b></Label>
                <div className={accessLevel !=0?'custom-issue-dis-box-user ml-3':'custom-issue-dis-box ml-3'}>
                   <p>
                    <ReadMorelatestComments htmlContent={replaceTicketHistoryComments} maxLength={200} />
                  </p>
                  <div className="d-flex flex-wrap">
                  {latestAttachmets &&
                    latestAttachmets.map((file, index) => {
                      const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
                      const fileName = file.ticketAttachment;

                      if (isImage(fileName)) {
                        return (
                          <p
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => showImg(fileUrl)}
                            className="d-flex"
                          >
                            <img
                              src={fileUrl}
                              alt="Attachment"
                              className="ml-2"
                              title="Image Attachment"
                              style={{ height: "50px" }}
                            />
                          </p>
                        );
                      } else if (isPdfFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files mb-0">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span  >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFilePdf}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }} >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else if (isExcelFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files mb-0">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileExcel}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }} >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else {
                        return (
                          <p key={index} className="latest-comment-files mb-0">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileWord}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }}>{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      }
                    })}
                  </div>
                </div>
               
              </Col>
              <Col md={12}>
                {state.ticketStatus !== "Closed" && (
                  <>
                    <Label for="Comment" className='mb-2 ml-3'>
                      <b>Partner Communication<span style={{ color: "red" }}> *</span> : </b>

                    </Label>
                    {/* <Input
                      id="ticketHistoryComment"
                      value={text}
                      rows={5}
                      type="textarea"
                      name="ticketHistoryComment"
                      onChange={handleTextChange}
                    /> */}
                    <ReactQuill
                      className="ml-3"
                      theme={'snow'}
                      modules={Editor.modules}
                      formats={Editor.formats}
                      value={text}
                      onChange={handleQuillChange}
                      bounds={'.quill-prod'}
                      placeholder='Please describe your problem' />
                  </>
                )}
              </Col>
              {state.ticketStatus !== "Closed" && (
                <>
                  <Col md={12} className='className= m-3'>
                    <Label for="Attachment">
                      <b>Helpful screenshots or images : </b>
                    </Label>
                    <Row>
                      <Col md={4}>
                      <label htmlFor="fileInput" className="custom-file-input-label text-center">
                      <i className="fas fa-upload"></i><br /> Choose File
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="ticketAttachment"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                      </Col>
                      <Col md={8}>
                      <span style={{ fontSize: "13px" }}><b>Attached File List</b></span>
                    <ul style={{ color: "green", fontSize: "13px" }}>
                      {selectedFiles
                        ? selectedFiles.map((file, index) => (
                          <li key={index}>
                            {file.name}
                            <button
                              className="btn"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <FontAwesomeIcon
                                color="red"
                                size="15px"
                                icon={faWindowClose}
                              />
                            </button>
                          </li>
                        ))
                        : ""}
                    </ul>

                      </Col>
                    </Row>
                   
                   
                  </Col>

                </>
              )}
            </Row>
          </Col>
        </Row>
      </Card>

      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'ticketAttachment': filePath, 'ticketAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" +
                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={
          <>
            Attachment
            <FontAwesomeIcon
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
              onClick={() =>viewDownloadFile({ ticketAttachment: popupFilePath,
                  ticketAttachmentId: 0,})
                // downloadFile({
                //   ticketAttachment: filePath,
                //   ticketAttachmentId: 0,
                // })
              }
            />
          </>
        }
        isOpen={filePoup}
        toggle={() => setFilePoup(!filePoup)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setFilePoup(!filePoup)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                popupFilePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Comment " + "By - " + ticketHistoryUpdatedBy}
        isOpen={isTicketHistoryTextOpen}
        toggle={() => setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)}
        size={"xl"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceticketHistoryText }} />
        {/* <Label>{ticketHistoryText}</Label> */}
        <div className="d-flex flex-wrap">
      {allFiles && allFiles.map((file, index) => {
        const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
        const fileName = file.ticketAttachment;

        return isImage(fileName) ? (
          <p style={{cursor:'pointer'}} onClick={()=>showImg(fileUrl)}>
             <img
            key={index}
            src={fileUrl}
            alt="Attachment"
           className="ml-2"
            title="Image Attachment"
            style={{ height: "100px",  }}
          />
          </p>
         
        ) : (
          <p key={index} className="ml-2">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <ui><li>{file.ticketAttachment.replace(/^static\//, "")}</li></ui>
            </a>
          </p>
        );
      })}
    </div>
       
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={isExpanded}
        toggle={() => setIsExpanded(!isExpanded)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpanded(!isExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p style={{ fontSize: "19px" }} className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} />
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
      <CustomModal
        header={"Latest Comment"}
        isOpen={isExpandedHistory}
        toggle={() => setIsExpandedHistory(!isExpandedHistory)}
        size={"xl"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpandedHistory(!isExpandedHistory)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p style={{ fontSize: "19px" }} className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceTicketHistoryComments }} />
        {/* <Label>{ticketHistoryText}</Label> */}
     
        <div className="d-flex flex-wrap">
                  {latestAttachmets &&
                    latestAttachmets.map((file, index) => {
                      const fileUrl = `https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/${file.ticketAttachment}`;
                      const fileName = file.ticketAttachment;

                      if (isImage(fileName)) {
                        return (
                          <p
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => showImg(fileUrl)}
                            className="d-flex"
                          >
                            <img
                              src={fileUrl}
                              alt="Attachment"
                              className="ml-2"
                              title="Image Attachment"
                              style={{ height: "50px" }}
                            />
                          </p>
                        );
                      } else if (isPdfFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span  >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFilePdf}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }}>{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else if (isExcelFile(fileName)) {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileExcel}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }} >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      } else {
                        return (
                          <p key={index} className="latest-comment-files ml-2">
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                              <span >
                                <FontAwesomeIcon
                                  style={{ color: "#05837c" }}
                                  icon={faFileWord}
                                />
                                &nbsp;<span style={{ fontSize: "15px" }} >{fileName.replace(/^static\//, "")}</span>
                              </span>
                            </a>
                          </p>
                        );
                      }
                    })}
                  </div>
      </CustomModal>
      <CustomModal
        header={"Amazon Case Details"}
        isOpen={openAmazonDetails}
        toggle={() => { setOpenAmazonDetails(!openAmazonDetails);setTempAmazonCaseUrl(amazonCaseUrl);setTempAmazonCaseId(amazonCaseId) }}
        size={"md"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => { setOpenAmazonDetails(!openAmazonDetails);setTempAmazonCaseUrl(amazonCaseUrl);setTempAmazonCaseId(amazonCaseId)}}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Row style={{ fontSize: "19px" }}>
          {/* <Col md={5}>
            <Label className='ml-1'><b>Amazon CaseId :</b></Label></Col> */}
          <Col md={12} >
            <div className="d-flex justify-content-center">
              <input
                type="text"
                id="tempAmazonCaseId"
                name="tempAmazonCaseId"
                value={tempAmazonCaseId}
                // className="form-control mb-2"
                className={`form-control mb-2 w-75 ${tempAmazonCaseUrl && tempAmazonCaseId == '' ? 'is-invalid' : ''}`}
                placeholder='Amazon Case ID'
                onChange={handleTextChange}
              />
            </div>
            <p className="text-danger d-flex justify-content-center">{numericIdMsg}</p>
          </Col>
          {/* <Col md={5}>
            <Label className='ml-1'><b>Amazon CaseUrl :</b></Label>

          </Col> */}
          <Col md={12} >
            <div className="d-flex justify-content-center">
              <input
                type="text"
                id="tempAmazonCaseUrl"
                name="tempAmazonCaseUrl"
                value={tempAmazonCaseUrl}
                // className="form-control mb-2"
                className={`form-control mb-2 w-75 ${tempAmazonCaseId && tempAmazonCaseUrl == '' ? 'is-invalid' : ''}`}
                placeholder="Amazon Case URL"
                onChange={handleTextChange}
              />
            </div>
            {errors.tempAmazonCaseUrl && <p className="text-danger d-flex justify-content-center">{errors.tempAmazonCaseUrl}</p>}
          </Col>
          <Col md={12} className="d-flex justify-content-center">
            <Button
              className="btn-background-color"
              disabled={ !tempAmazonCaseUrl || !tempAmazonCaseId || !!errors.tempAmazonCaseUrl ||amazonButtonDisable?true:false}
              onClick={() =>
              {((state.assignedToID != loggedInUser.userId) && state.assignedToID != null ? setwarningpopupOpen(true):state.assignedToID == null? saveData(tempAmazonCaseId,tempAmazonCaseUrl,1):setyesButton(false));
                (state.assignedToID == loggedInUser.userId ?setyesButton(true):setyesButton(false));
                setTemp(5);
                (state.assignedToID == loggedInUser.userId ?saveData(tempAmazonCaseId,tempAmazonCaseUrl,1):setyesButton(false));}
              }>
              Amazon Ticket Raised
            </Button>
          </Col>
        </Row>



        {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} /> */}
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>

      <CustomModal
                header={""}
                isOpen={warningpopupOpen}
                toggle={() => setwarningpopupOpen(!warningpopupOpen)}
                size={"md"}
                className="curve-popup"
                // footer={
                //     <>
                //         <Button
                //             color={"link"}
                //             onClick={() => setwarningpopupOpen(!warningpopupOpen)}
                //         >
                //             Cancel
                //         </Button>
                //     </>
                // }
            >
              <Card className="curve-popup p-3 ">
              <Row>
              <Col md={12}>
                <div className="modal-body">
                    <p style={{ fontSize: "18px" }}>Do you want to get the ticket assigned to yourself?</p>
                    {/* <h6 style={{ fontSize: "14px" }}>Note : You can only comment on the ticket without getting it assigned to yourself.</h6> */}
                </div>
                <div className='mb-2 d-flex justify-content-end mr-3'>
                                    <Button
                                       color="primary" onClick={() => handleYesAction()}
                                       >
                                        Yes
                                    </Button> &nbsp;
                                    <Button color="secondary" onClick={() => handleNoAction()} style={{ marginLeft: "10px" }}
                                        >
                                        No
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        </Card>
            </CustomModal>

            <CustomModal
        header={
          <>
            Attachment
            <FontAwesomeIcon
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
              onClick={() => viewDownloadFile({ ticketAttachment: popupFilePath,
                ticketAttachmentId: 0,})
                // downloadFile({
                  // ticketAttachment: filePath,
                  // ticketAttachmentId: 0,
                // })
              }
            />
          </>
        }
        isOpen={filePoup}
        toggle={() => setFilePoup(!filePoup)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setFilePoup(!filePoup)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                popupFilePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    UserPermission: state.accountOverview.store.StoreUserPermission,
  };
};

export default connect(mapStateToProps)(OpsDashViewTicket);
