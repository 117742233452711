import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Input, Button, Label } from 'reactstrap'
import { connect } from 'react-redux';
import CustomModal from '../../../../../../../../../../Components/Modals/CustomModal';
import Rename from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Rename.svg'
import Comment_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Comment_Dark.svg'
import TickMark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/TickMark.svg'
import Download from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Download.svg'
import xlsxIcon from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/xlsxIcon.svg'
import Close from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Close.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faAngleDown, faAngleUp, faCloudUploadAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Send from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Send.svg'
import addAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/addAssetNoteService';
import getAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAssetNoteService';
import RenameFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetNewService';
import DeleteFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/DeleteFileAssetNewService';
import ReactTable from 'react-table';
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
function OtherBrandAsset(props) {
    const [imageUrl, setImageUrl] = useState(false)
    const [isOpengalleryPopUp, setIsOpengalleryPopUp] = useState(false)
    const [isAssetPopUp, setsAssetPopUp] = useState(false)
    const [assetPageRender, setAssetPageRender] = useState(false);
    const [fileListOfAssets, setFileListOfAssets] = useState(false);
    const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)
    const [selectedFileObject, setSelectedFileObject] = useState();
    const [editedFileName, setEditedFileName] = useState();
    const [isClickEditName, setIsClickEditName] = useState(false);
    const [isSaveButton, setIsSaveButton] = useState(true)
    const [isDeletePopUpOPen, setIsDeletePopUpOPen] = useState()
    const [isCommentsPopUpOPen, setIsCommentsPopUpOPen] = useState()
    const [note, setNote] = useState();
    const [noteList, setNoteList] = useState([])
    const [fileForDelete, setFileForDelete] = useState('');
    const [fileType, setFileType] = useState();
    const [isDeleteConfirmPopUpOPen, setIsDeleteConfirmPopUpOPen] = useState();
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [isFileNamePopUpOPen,setIsFileNamePopUpOPen]=useState(false)
    const [fileName, setFileName]=useState('')
    const [demoImage,setDemoImage]=useState()
    useEffect(() => {
        // setFileListOfAssets(props.fileListOfAssets)

    }, []);

    const handleOpenFileInfo = () => {
        setIsInfoTabOpen(!isInfoTabOpen)
        setSelectedFileObject(false)
    }

    const getAssetNote = (id, path, file) => {
        setIsCommentsPopUpOPen(true)
        let data = {
            assetDetails: id
        }
        getAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res)
            }
            showNote(path,file)
        })
    }

    const handleSaveRename = (imageUrl) => {
        let data;
        if (imageUrl != '') {
            props.fileListOfAssets.map((img) => {
                if (img.filePath == imageUrl)
                    data = {
                        integerId: props.storeOverview.integerID,
                        assetCategory: props.assetCategory,
                        assetIdentifier: selectedFileObject.assetIdentifier,
                        newName: editedFileName,
                        fileId: selectedFileObject.id
                    }
            })
        }
        else {
            data = {
                integerId: props.storeOverview.integerID,
                assetCategory: props.assetCategory,
                subCategoryId:props.subCategoryId,
                assetIdentifier: selectedFileObject.assetIdentifier,
                newName: editedFileName,
                fileId: selectedFileObject.id

            }
        }
        RenameFileAssetNewService(props.token, data).then((response) => {
            if (response) {
                response.fileList.map((file) => {
                    if (file.id === selectedFileObject.id) {
                        setSelectedFileObject(file)
                    }
                })
                setIsClickEditName(false)
                const imageFiles = response.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['jpg', 'png', 'jpeg'].includes(extension);
                });

                // Set the filtered list
                setFileListOfAssets(imageFiles);
                setEditedFileName()
                console.log(response)
            }
        })
    }
    const addAssetNote = () => {
        let data = {
            note: note,
            assetDetails: selectedFileObject.id
        }
        addAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res.noteList)
                setNote('')
                setIsCommentsPopUpOPen(false)

            }
        })
    }
    const handleDeleteFile = (imageUrl, fileTypeNew) => {
        let data;
        if (imageUrl != '') {
            if (fileType == 'image' || fileTypeNew == 'image') {
                props.allFileListOfAssets.map((img) => {
                    if (img.filePath == imageUrl)
                        data = {
                            integerId: props.storeOverview.integerID,
                            assetCategory: props.assetCategory,
                            subCategoryId:props.subCategoryId,
                            assetIdentifier: selectedFileObject.assetIdentifier,
                            fileId: img.id,
                            fileType: fileType ? fileType : 'image'
                        }
                })
            }
            if (fileType == 'graphic') {
                props.allFileListOfAssets.map((img) => {
                    if (img.originalGA != '') {
                        if (img.originalGA[0].filePath == imageUrl)
                            data = {
                                integerId: props.storeOverview.integerID,
                                assetCategory: props.assetCategory,
                                subCategoryId:props.subCategoryId,
                                assetIdentifier: selectedFileObject.assetIdentifier,
                                fileId: img.originalGA[0].id,
                                fileType: fileType
                            }
                    }

                })
            }

        }
        DeleteFileAssetNewService(props.token, data).then((response) => {
            if (response) {
                setSelectedFileObject()
                setFileListOfAssets()
                const imageFiles = response.returnList.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['jpg', 'png', 'jpeg'].includes(extension);
                });

                // Set the filtered list
                props.setFileListOfAssets(imageFiles);
                console.log(response)
                props.setAllFileListOfAssets(response.returnList.fileList)
                console.log(imageFiles)
                // setFileListOfAssets(response.returnList.fileList)
                setIsInfoTabOpen(false)
                setIsOpengalleryPopUp(false)
                setFileForDelete('')
                setFileType(false)
                setIsDeleteConfirmPopUpOPen(false)
            }
        })
    }
    const handleRenameFileAssets = () => {
        setIsClickEditName(true)
        setIsSaveButton(false)
    }
    
    const show = (path,fullPath) => {
        console.log(path);
        // return true
        const separatedPath = path.split("/");
        const ext = path.split(".")[1];
        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
          setFilePath(fullPath);
          setIsAttachmentOpen(true);
          setTimeout(() => {
            setIsAttachmentOpen(false);
          }, 200);
        } else {
          setFilePath(fullPath);
          setIsAttachmentOpen(true);
        }
      };
      const showNote = (path,fullPath) => {
        console.log(path);
        // return true
        // const separatedPath = path.split("/");
        const ext = path.split(".")[1];
        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
          setFilePath(fullPath);
        //   setIsAttachmentOpen(false);
          setDemoImage(true)
        } else {
          setFilePath(fullPath);
          setDemoImage(false)

        //   setIsAttachmentOpen(true);
        }
      };
     
    const handleDownloadImage = async (url, type) => {
        if (type == "DownloadeImage") {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Network response was not ok for ${selectedFileObject.id}`);
                    console.log(selectedFileObject)
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = selectedFileObject.actualFileName || 'downloaded_file';
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Failed to download files:', error);
            }
        }
        else {
            try {

                const response = await fetch(url);
                if (!response.ok) throw new Error(`Network response was not ok for ${selectedFileObject.originalGA[0].id}`);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                console.log(selectedFileObject)
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = selectedFileObject.originalGA[0].actualFileName || 'downloaded_file';
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Failed to download files:', error);
            }
        }

    };
    const setImageInfo = (imageUrl) => {
        console.log(imageUrl)
        {
            props.fileListOfAssets.map((img) => {
                if (img.filePath == imageUrl) {
                    setSelectedFileObject(img)
                    setEditedFileName(img.actualFileName)
                }
            })
        }
    }
    const getImageExtension = (imageUrl) => {
        const matches = imageUrl.match(/\.(jpg|jpeg|png|svg)$/i);
        if (matches) {
            if (matches.length > 1) {
                return matches['input']
            }
            else {
                return null
            }
        }
        else {
            return null;
        }
    };
    const handleAssetsSlider = () => {
        const imageUrls = props.fileListOfAssets && props.fileListOfAssets.reduce((acc, fileUrl) => {
            if (getImageExtension(fileUrl.filePath) != null) {
                return acc.concat(fileUrl.filePath);
            }
            return acc;
        }, []);

        return <Carousel images={imageUrls} />;
    }
    const Carousel = ({ images }) => {
        const [currentSlide, setCurrentSlide] = useState(0);

        const nextSlide = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        };

        const prevSlide = () => {
            setCurrentSlide((prevSlide) =>
                prevSlide === 0 ? images.length - 1 : prevSlide - 1
            );
        };
        const filteredImageUrlArray = images.filter((url) => url.trim() !== '');
        const visibleImages = filteredImageUrlArray.slice(currentSlide, currentSlide + 5);
        return (
            <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary prev-next" disabled={currentSlide <= 0 ? true : false} onClick={prevSlide}>&lt; </button>
                {visibleImages.map((image, index) => (
                    <div className={`p-2 mt-2 mb-1 `} style={{ backgroundColor: imageUrl === image ? '#D0EFB1' : '' }}>
                        {image != null ?
                            <img key={index} height={80} width={80} src={image} alt={`Slide ${index + 1}`} onClick={() => { setImageUrl(image); setImageInfo(image); setIsClickEditName(false) }} />
                            : ''}
                    </div>
                ))}
                <button className="btn btn-primary prev-next" disabled={currentSlide == filteredImageUrlArray.length - 5 ? true : false} onClick={nextSlide}> &gt;</button>
            </div>
        );
    };
    const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
    const handleFileName=(file)=>{
        setFileName(file)
        setIsFileNamePopUpOPen(true)
    }
    const columns = [
        {
            Header:'Id',
            accessor:'id',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'File Name',
            accessor:'actualFileName',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0 comment-paragraph" onClick={()=>handleFileName(cellInfo.value)}>{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'File Type',
            accessor:'fileType',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0 comment-paragraph">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Uploaded At',
            accessor:'createdAt',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Uploaded By',
            accessor:'userName',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Note',
            accessor:'id',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 cursor-pointer">
                <button onClick={() =>{getAssetNote(cellInfo.value, cellInfo.original.fileName,cellInfo.original.filePath); setSelectedFileObject(cellInfo.original); }} className='btn mt-1'>
                    <img className="comments-bg-color" height={25} width={25} src={Comment_Dark} alt='not found' />
                </button>
              </div>
            ),
          },
          {
            Header:'Actions',
            accessor:'actualFileName',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  {
                    fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                      <>
                        <FontAwesomeIcon
                            className="ml-3"
                            style={{ cursor: 'pointer' }}
                            icon={faDownload}
                            onClick={() => {handleDownloadImage(cellInfo.original.filePath, "DownloadeImage");setSelectedFileObject(cellInfo.original)}}
                        />
                      <FontAwesomeIcon onClick={() => {setIsDeletePopUpOPen(true);setSelectedFileObject(cellInfo.original)}} icon={faTrash} className="ml-3" style={{ cursor: 'pointer' }}/>
                        </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          className="ml-3"
                          style={{ cursor: 'pointer' }}
                          icon={faEye}
                          onClick={() => {show(cellInfo.original.fileName,cellInfo.original.filePath);setSelectedFileObject(cellInfo.original)}}
                        />
                        <FontAwesomeIcon
                          className="ml-3"
                          style={{ cursor: 'pointer' }}
                          icon={faDownload}
                          onClick={() => {handleDownloadImage(cellInfo.original.filePath, "DownloadeImage");setSelectedFileObject(cellInfo.original)}}
                        />
                        <FontAwesomeIcon onClick={() => {setIsDeletePopUpOPen(true);setSelectedFileObject(cellInfo.original)}} icon={faTrash} className="ml-3" style={{ cursor: 'pointer' }}/>
                      </>
                    )
                  }

                </div>
              ),
          },
    ]
    return (
        <>
            <Row>
                
               <Col md={12}>
               <ReactTable
                data={props.allFileListOfAssets}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}
                noDataText={"No Data Found."}
                />
               </Col>
               
            </Row>
            <CustomModal
                isOpen={isDeletePopUpOPen}
                toggle={() => { setIsDeletePopUpOPen(!isDeletePopUpOPen); setFileForDelete(false); }}
                size={"md"}
                className="curve-popup"
            >
                <Card className="curve-popup p-3 ">
                    <Row>
                        <Col md={12}>
                            <div className='ml-4'>
                                <p>Which file do you want to delete?</p>
                                <label className='ml-2 '>
                                    <input
                                        type='radio'
                                        name='fileType'
                                        onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.filePath : ''); setFileType('image'); }}
                                    />&nbsp;
                                    File : {selectedFileObject ? selectedFileObject.actualFileName : ''}
                                </label>
                                {selectedFileObject ? selectedFileObject.originalGA != '' ?
                                    <label className='ml-2'>
                                        <input
                                            type='radio'
                                            name='fileType'
                                            onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].filePath : '' : ''); setFileType('graphic'); }}
                                        />&nbsp;
                                        Editable File : {selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].actualFileName : 'Not Availble' : ''}
                                    </label> : '' : ''}
                            </div>
                            <div className='mb-2 ml-4'>
                                <Button
                                    onClick={() => { setIsDeleteConfirmPopUpOPen(true); setIsDeletePopUpOPen(false) }}
                                    className='delete-button-background-none mt-1'
                                    disabled={fileForDelete == '' ? true : false}>
                                    <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                    Delete
                                </Button> &nbsp;
                                <Button
                                    onClick={() => { setFileForDelete(false); setIsDeletePopUpOPen(!isDeletePopUpOPen) }}
                                    className=' mt-1'>
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </CustomModal>
            <CustomModal
                    isOpen={isDeleteConfirmPopUpOPen}
                    toggle={() => { setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen); setFileForDelete(false); }}
                    size={"md"}
                    className="curve-popup"
                >
                    <Card className="curve-popup p-3 ">
                        <Row>
                            <Col md={12}>
                                <div>
                                    <h6>
                                        Are you sure you want to delete file?
                                    </h6>
                                </div>
                                <div className='mb-2'>
                                    <Button
                                        onClick={() => handleDeleteFile(fileForDelete,'false')}
                                        className='delete-button-background-none mt-1'
                                        disabled={fileForDelete == '' ? true : false}>
                                        <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                        Delete
                                    </Button> &nbsp;
                                    <Button
                                        onClick={() => { setFileForDelete(false); setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen) }}
                                        className=' mt-1'>
                                        Cancel
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </CustomModal>
                <CustomModal
                    isOpen={isOpengalleryPopUp}
                    toggle={() => { setIsOpengalleryPopUp(!isOpengalleryPopUp); setIsClickEditName(false) }}
                    size={"lg"}
                    className="asset-carousel"
                >
                    <Row
                        className={`m-1 ${isAssetPopUp ? 'd-none' : ''}`}>
                        <Col md={12} className=''>
                            {
                                selectedFileObject && (
                                    <div className='d-flex justify-content-between mb-2'>
                                        <div className='d-flex align-items-center mb-2'>
                                            {/* File Name */}
                                            <h6 style={{ color: '#fff' }}>
                                                <b>File Name : </b>&nbsp;
                                            </h6>
                                            {/* Edit File Name Input */}
                                            {isClickEditName ? (
                                                <>
                                                    <Input
                                                        className='w-50'
                                                        type='text'
                                                        value={editedFileName ? editedFileName : selectedFileObject.actualFileName}
                                                        onChange={(e) => setEditedFileName(e.target.value)}
                                                    />&nbsp;
                                                    <h6>
                                                        <button
                                                            title='save'
                                                            className='info-button-background-none'
                                                            onClick={() => handleSaveRename(imageUrl)}
                                                            disabled={!editedFileName}
                                                        >
                                                            <img className='carousel-img-background-none' src={TickMark} alt='not found' />
                                                        </button>
                                                    </h6>
                                                </>
                                            ) : (
                                                <h6 style={{ color: '#fff' }}>
                                                    {editedFileName ? editedFileName : selectedFileObject.actualFileName} &nbsp;
                                                    <button
                                                        title='Edit'
                                                        className='info-button-background-none'
                                                        onClick={() => handleRenameFileAssets()}
                                                    >
                                                        <img className='carousel-img-background-none p-1' src={Rename} alt='not found' />
                                                    </button>
                                                </h6>
                                            )}
                                            {/* Download Button */}
                                            <h6>
                                                <button
                                                    onClick={() => handleDownloadImage(selectedFileObject.filePath, "DownloadeImage")}
                                                    className='info-button-background-none'
                                                    title='Download'
                                                >
                                                    <img className='carousel-img-background-none-download p-1' src={Download} alt='not found' />
                                                </button>
                                            </h6>
                                        </div>
                                        {/* Close Button */}
                                        <div className='ml-auto'>
                                            <button
                                                onClick={() => { setIsOpengalleryPopUp(false); setIsClickEditName(false) }}
                                                className='info-button-background-none '
                                                title='Close'
                                            >
                                                <img src={Close} alt='not found' />
                                                {/* <img className=' text-white p-1' src={Close} alt='not found' /> */}
                                            </button>
                                        </div>
                                    </div>

                                )}
                            <div className='file-view-div'>
                                <img
                                    src={imageUrl}
                                    style={{ background: '#fff' }}
                                    title="File Display"
                                    className='file-view p-2'
                                />
                                <h6 className='d-flex justify-content-center delete-btn-popup' >
                                    <Button
                                        onClick={() => {handleDeleteFile(imageUrl,'image');}}
                                        className='delete-button-background-none mt-1'>
                                        <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                        Delete
                                    </Button>
                                </h6>
                            </div>
                        </Col>
                        <Col md={12} className=''>
                            {handleAssetsSlider()}
                        </Col>
                    </Row>
                </CustomModal>
                <CustomModal
                    isOpen={isCommentsPopUpOPen}
                    toggle={() => setIsCommentsPopUpOPen(false)}
                    size={"md"}
                    className="curve-popup position-of-pop-up"
                >
                    <Row>
                        <Col md={12} className='p-0'>
                            {/* <div>
                                {selectedFileObject && (
                                    <p className='align-center '>
                                         <iframe
                                        src={selectedFileObject.filePath}
                                        title="File Display"
                                        width="100%"
                                        height="500"
                                        ></iframe>
                                        <img src={selectedFileObject.filePath} alt='not found' width={'100%'} height={'100%'} />
                                    </p>
                                )}
                            </div> */}
                            {
                                demoImage?
                                <div className='xlx-file'>
                                    <img src={xlsxIcon}/>
                                </div>
                                :
                                <div>
                                <iframe
                                    src={filePath}
                                    title="File Display"
                                    width="100%"
                                    height="250"
                                    ></iframe>
                                </div>
                            }
                           
                        </Col>
                        <Col md={12} className="scrollable-div p-0 overflow-auto" style={{ maxHeight: '187px' }}>
                            {noteList && noteList.map((note) => (<>
                                {note.loggedInUser === 1 ?
                                    (<div className='d-flex justify-content-end p-2'>
                                        <p className='p-2 mb-0 d-inline chat-background-color-sent'>
                                            {note.notes} <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>) : <div className='d-flex justify-content-start align-items-end p-2'>
                                        {/* <img src={User} title={note.userName} className='pr-2' />  */}
                                        <div className='p-1 user-short-name'>{note.userShortName.toUpperCase()}</div>
                                        <p className='d-inline p-2 mb-0 chat-background-color '>
                                            {note.notes}
                                            <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>}</>)
                            )
                            }
                        </Col>
                        <Col md={12} className='d-flex align-items-between pl-2 pr-2 pt-1'>
                            <Input className='w-100 '
                                style={{ borderradius: '10px' }}
                                placeholder="Add your comments"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <button className='btn-background-color-comments'
                            disabled={!note}
                             onClick={() => { addAssetNote() }}><img src={Send} alt='not found' /></button>
                        </Col>
                    </Row>
                </CustomModal>
                <CustomModal
                    header={<> Attachment
                    <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => handleDownloadImage(filePath, "DownloadeImage")} />
                    </>}
                    isOpen={isAttachmentOpen}
                    toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
                    size={"lg"}
                    className='curve-popup'
                    footer={
                    <>
                        <Button
                        color={"link"}
                        onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
                        >
                        Cancel
                        </Button>
                    </>
                    }
                >
                    <Label>
                    <div>
                        <iframe
                        src={filePath}
                        title="File Display"
                        width="100%"
                        height="500"
                        ></iframe>
                    </div>
                    </Label>
                </CustomModal>
                <CustomModal
                    isOpen={isFileNamePopUpOPen}
                    toggle={() => setIsFileNamePopUpOPen(false)}
                    size={"sm"}
                    className="curve-popup"
                >
                    <Card className='curve-popup p-3'>
                        <Col md={12}>
                        <p>{fileName}</p>
                        </Col>
                    </Card>
                </CustomModal>

        </>

    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};

export default connect(mapStateToProps)(OtherBrandAsset)