import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import GetBrandListOAmazonProductService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetBrandListOAmazonProductService';
import { Button, Card, Col, Row } from 'reactstrap';
import GetFileListByCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListByCategoryService';
import BackButton from "../../../../.././../../../../designUtils/Logo/BackButton.svg"
import UploadAllTypeOfAssetsFile from './Components/UploadAllTypeOfAssetsFile';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loaders";
import ImageAndLogo from './Components/Brand/ImageAndLogo';
import ProductImageAndLogo from './Components/Product/ProductImageAndLogo';
import GetFileListBySubCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListBySubCategoryService';
import FetchAllCategoryAndSubCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/FetchAllCategoryAndSubCategoryService';
import OtherProductAssets from './Components/Product/OtherProductAssets';
import UploadImagesAndOriginalFiles from './Components/UploadImagesAndOriginalFiles'
import AmazonProductLiveImages from './Components/Product/AmazonProductLiveImages';
import UploadVideosPage from './Components/Brand/UploadVideosPage';
import VideosForBrand from './Components/Brand/VideosForBrand';
import ProductCatalogueDetailsFile from './Components/Product/ProductCatalogueDetailsFile';
import AssetsProductList from './Components/Product/AssetsProductList';
import CreateBrandProfileMainPage from '../../Questionnaire/CreateBrandProfileMainPage';
import GetBrandProfileListService from '../../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetBrandProfileListService';

function ProductDetails(props) {
  const [amazonProductBrandList, setAmazonProductBrandList] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [fileListOfAssets, setFileListOfAssets] = useState(false);
  const [ASIN, setAsin] = useState();
  const [ASINImage, setAsinImage] = useState();
  const [isProductSubtabs, setIsProductSubtabs] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState(15)
  const [uploadImagesPage, setUploadImagesPage] = useState(false)
  const [loadScreen, setLoadScreen] = useState(false)
  const [allFileListOfAssets, setAllFileListOfAssets] = useState([]);
  const [subCategoryText, setSubCategoryText] = useState('')
  const [groupedSubCategories, setGroupedSubCategories] = useState({});
  const [activeTab, setActiveTab] = useState("3"); 
  const [fileListOfAssetsVideo, setFileListOfAssetsVideo] = useState();
  const [productName,setProductName]=useState('')


  const filteredProductList =
    amazonProductBrandList &&
    amazonProductBrandList.filter((row) => {
      const asin = row.asin.toLowerCase();
      const productTitle = row.productTitle.toLowerCase();

      if (row.skus != null) {
        const skus = row.skus.join("").toLowerCase();
        if (searchTerm !== "") {
          return (
            asin.includes(searchTerm.toLowerCase()) ||
            productTitle.includes(searchTerm.toLowerCase()) ||
            skus.includes(searchTerm.toLowerCase())
          );
        }
      }
      if (searchTerm !== "") {
        return (
          asin.includes(searchTerm.toLowerCase()) ||
          productTitle.includes(searchTerm.toLowerCase())
        );
      }
      else {
        return true
      }

    });
    
    const tabMapping = {
      "1": "Media",
      "2": "Documents",
      "3": "ProductInformation",
    };
    
    useEffect(() => {
      let data = {
        integerId: props.storeOverview.integerID,
        assetIdentifier:ASIN?ASIN:''
      };
      FetchAllCategoryAndSubCategoryService(props.token,data).then((response) => {
        if (response) {
          console.log(response);
          const filteredSubCategories = response.subCategory && response.subCategory.filter((subCat) => subCat.assetCategory === props.catId);
          console.log("filteredSubCategories", filteredSubCategories);
    
          if (filteredSubCategories && filteredSubCategories.length > 0) {
            const grouped = filteredSubCategories.reduce((acc, subCat) => {
              const section = subCat.subCategoryName.split(" - ")[0].replace(/\s+/g, "");;
              const nameWithoutSection = subCat.subCategoryName.split(" - ")[1];
    
              if (!acc[section]) {
                acc[section] = [];
              }
    
              acc[section].push({
                ...subCat,
                subCategoryName: nameWithoutSection,
              });
    
              return acc;
            }, {});
    
            console.log("groupedSubCategories", grouped);
            setGroupedSubCategories(grouped);
    
            // Set default values for the first subcategory in the first section
            const firstSection = Object.keys(grouped)[0];
            const firstSubCategory = grouped[firstSection][0];
    
            setSubCategoryText(firstSubCategory.subCategoryName);
            setSubCategoryId(firstSubCategory.id);

          }
        }
      });
      GetBrandListOAmazonProductService(props.token, data).then((response) => {
        if (response) {
          setAmazonProductBrandList(response.productList);
          console.log('response');
          console.log(response);
        }
      });
      // props.token, props.catId, props.storeOverview.integerID,ASIN
    }, []);
    console.log(subCategoryId)
    // Example usage of groupedSubCategories with activeTab
    const activeTabData = groupedSubCategories[tabMapping[activeTab]] || [];
    
    const handleMainTabs = (tab) => {
      setActiveTab(tab); 
      
      console.log(groupedSubCategories)
      console.log(groupedSubCategories.Documents)
 
      if(tab==='1'){
        setSubCategoryId(groupedSubCategories.Media[0].id)
        GetFileListBySubCategory(groupedSubCategories.Media[0].id,ASIN)
        // handleProductSubCategory(groupedSubCategories.Media?groupedSubCategories.Media[0].id)
        setSubCategoryText(groupedSubCategories.Media[0].subCategoryName)
      }
      else if(tab==='2'){
        setSubCategoryId(groupedSubCategories.Documents[0].id)
        GetFileListBySubCategory(groupedSubCategories.Documents[0].id,ASIN)
        setSubCategoryText(groupedSubCategories.Documents[0].subCategoryName)
        // handleProductSubCategory(groupedSubCategories.Documents?groupedSubCategories.Documents[0].id)
      }
      else{
        setSubCategoryId(groupedSubCategories.ProductInformation[0].id)
        GetFileListBySubCategory(groupedSubCategories.ProductInformation[0].id,ASIN)
        setSubCategoryText(groupedSubCategories.ProductInformation[0].subCategoryName)
        // handleProductSubCategory(groupedSubCategories.ProductInformation?groupedSubCategories.ProductInformation[0].id:'')
      }
      console.log(tab)
    };
  
  const setProductDetails = (product) => {
    console.log(product)
    setIsProductSubtabs(true)
    setAsin(product.asin)
    setProductName(product.productTitle)
    setAsinImage(product.itemImage)
    setIsInfoTabOpen(false);
    GetFileListBySubCategory(subCategoryId,product.asin)
  }
  const handleBackFromUploadAssets = () => {
    setUploadImagesPage(false)

  }
  const handleProductSubCategory = (tab) => {
    console.log(tab)
    setSubCategoryId(tab.id);
    setSubCategoryText(tab.subCategoryName)
    setIsInfoTabOpen(false);
}
const GetFileListBySubCategory = (id,asin) => {
  setLoadScreen(true)
    let data = {
        integerId: props.storeOverview.integerID,
        assetCategory: props.catId,
        subCategoryId: id ? id : '',
        assetIdentifier:asin?asin:''
    };
    GetFileListBySubCategoryService(props.token, data).then((response) => {
        if (response) {
            const imageFiles = response.fileList.filter(file => {
                const extension = file.fileName.toLowerCase().split('.').pop();
                return ['jpg', 'png', 'jpeg'].includes(extension);
            });
            const videoFiles = response.fileList.filter(file => {
              const extension = file.fileName.toLowerCase().split('.').pop();
              return ['mp4', 'mov', 'avi', 'mkv'].includes(extension);
          });
            setFileListOfAssetsVideo(videoFiles)
            console.log(response)
            setFileListOfAssets(imageFiles);
            setAllFileListOfAssets(response.fileList)
            // console.log(response.fileList);
            // setAssetImagePage(true)
            setLoadScreen(false)
        }
    });
}

  const handleUploadAssetsUi = () => {
    setUploadImagesPage(true)
    setIsInfoTabOpen(false);
  }
  const handleBackbutton = () => {
    setIsProductSubtabs(false)
    setActiveTab('3')
    setSubCategoryId(15)
  }

  const handleBackFromUploadAssetsImages=()=>{
    setUploadImagesPage(false)
   }
  const renderUploadAssetsPage = () => {


    if (subCategoryId ==6 || subCategoryId == 7 || subCategoryId == 8 || subCategoryId == 11 || subCategoryId == 12) {
      console.log(subCategoryId)
      return (
            <UploadImagesAndOriginalFiles
                subCategoryId={subCategoryId}
                handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                assetIdentifier={ASIN}
                setFileListOfAssets={setFileListOfAssets}
                assetCategory = {props.catId} />
        )
    }
      else if(subCategoryId ==14){
        return(
          <UploadVideosPage
          subCategoryId={subCategoryId}
          handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
          assetIdentifier={ASIN}
          fileListOfAssetsVideo={fileListOfAssetsVideo}
          setFileListOfAssetsVideo={setFileListOfAssetsVideo}
          setFileListOfAssets={setFileListOfAssets}
          assetCategory = {props.catId}
          />
        )
      }

    else {
      console.log(subCategoryId)
        return (
            <UploadAllTypeOfAssetsFile
                subCategoryId={subCategoryId}
                handleBackFromUploadAssets={handleBackFromUploadAssets}
                setFileListOfAssets={setFileListOfAssets}
                setAllFileListOfAssets={setAllFileListOfAssets}
                flagNumber={10}
                assetIdentifier={ASIN} 
                assetCategory = {props.catId}/>
        )
    }
}

  const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)

  const handleOpenFileInfo = () => {
    setIsInfoTabOpen(!isInfoTabOpen)   
}
const [brand,setBrand]= useState({
  id:'New',
  brandName:''
});
const closeQuestionnaire = () => {
  let data = {
      integerId:props.storeOverview.integerID
  }
  GetBrandProfileListService(props.token,data).then((response) => {
      if (response) {
          // setBrandProfile(response.brandList);
          // setShowCreateBrandProfile(true)
      }
  });
  
}
  return (
    <>
      {uploadImagesPage ? renderUploadAssetsPage() :
        <Row>
          {!isProductSubtabs ?
             <AssetsProductList
             setProductDetails={setProductDetails}/>
             :
            <>
              <Col md={12}>
                <Card className='p-2 mb-3 child-card' >
                  <Row>
                    <Col md={12}>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <button
                            onClick={() => { handleBackbutton() }}
                            className="hover-btn-link back-btn ml-2 mb-3 mr-1 "  >
                            <img src={BackButton} alt="not found" />
                          </button>
                          <h5 className='product-name' title={productName}><b>{ASIN} : {productName}</b></h5>
                        </div>
                        {
                          (subCategoryId !==6 && activeTab !=3 )?
                          <>
                           { props.UserPermission.AssetGallery_UploadProductAssets && 
                            <Button
                              onClick={() => handleUploadAssetsUi()}
                              className='btn-background-color-Proceed-gallery  ml-2'
                            // disabled={!brand}
                            >
                              Upload Assets
                            </Button>}
                          </>:''
                        }
                      </div>
                    </Col>
                    <Col md={12}>
                    <div className="d-flex">
                            <p
                              className={` ${
                                activeTab === "3" ? "selected-tab-assets-tab" : "assets-tab-not-selected"
                              }`}
                              onClick={() => handleMainTabs("3")}
                            >
                              <b>Product Information</b>
                            </p>
                            <p
                              className={` ml-3 ${
                                activeTab === "1" ? "selected-tab-assets-tab" : "assets-tab-not-selected"
                              }`}
                              onClick={() => handleMainTabs("1")}
                            >
                              <b>Media</b>
                            </p>
                            <p
                              className={`ml-3 ${
                                activeTab === "2" ? "selected-tab-assets-tab" : "assets-tab-not-selected"
                              }`}
                              onClick={() => handleMainTabs("2")}
                            >
                              <b>Documents</b>
                            </p>
                          </div>
                    
                      <div className='d-flex mt-1 border-assets-subtypes'>
                        { activeTabData.map((sub) =>
                        (<>
                          {sub.assetCategory == props.catId ?
                            <p className={`mb-0 d-flex ${sub.id === subCategoryId ? 'selected-tab-assets-subtab ' : 'not-selected-tab-assets-subtab'}`}
                            onClick={() => { handleProductSubCategory(sub);  GetFileListBySubCategory(sub.id,ASIN);}}
                            >
                             {sub.subCategoryName}
                             {
                              sub.subCategoryName !=="Catalogue Details" && sub.subCategoryName !=="Questionnaire"?
                              <p className='Assets-Counts mb-0'>{sub.count}</p>:''
                             }
                           
                            </p>
                            : ''
                          }</>)
                        )
                        }
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
             
                <LoadingOverlay
                  active={loadScreen}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: '#f1f4f6',
                      opacity: 1,
                    }),
                    content: (base) => ({
                      ...base,
                      color: '#000',
                    }),
                  }}
                  spinner={<Loader active type="ball-pulse" />}
                  text="Loading asset details..."
                >
                {subCategoryId == 15 ?
                  <CreateBrandProfileMainPage 
                    closeQuestionnaire={closeQuestionnaire} 
                    brand={brand} 
                    asin={ASIN}
                    categoryID={2}/>:
                subCategoryId ===6 ?
                <AmazonProductLiveImages
                subCategoryId={subCategoryId}
                handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                assetIdentifier={ASIN}
                setFileListOfAssets={setFileListOfAssets}
                assetCategory = {props.catId}
                filteredProductList={filteredProductList}
                ASINImage={ASINImage}
              />
               :subCategoryId ===7 || subCategoryId ===8 || subCategoryId ===11 || subCategoryId ===12 ?
                <ProductImageAndLogo
                  // changeTabs={changeTabs}
                  subCategoryId={subCategoryId} 
                  fileListOfAssets={fileListOfAssets} 
                  assetIdentifier={ASIN}
                  assetCategory = {props.catId}
                  handleOpenFileInfo={handleOpenFileInfo}
                  isInfoTabOpen={isInfoTabOpen}
                  setIsInfoTabOpen={setIsInfoTabOpen}
                  setFileListOfAssets={setFileListOfAssets}
                  setAllFileListOfAssets={setAllFileListOfAssets}
                  allFileListOfAssets={allFileListOfAssets}
                  />
                  : subCategoryId === 14?
                  <VideosForBrand
                  subCategoryId={subCategoryId}
                  handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                  assetIdentifier={ASIN}
                  handleOpenFileInfo={handleOpenFileInfo}
                  fileListOfAssetsVideo={fileListOfAssetsVideo}
                  setIsInfoTabOpen={setIsInfoTabOpen}
                  isInfoTabOpen={isInfoTabOpen}
                  setFileListOfAssetsVideo={setFileListOfAssetsVideo}
                  setFileListOfAssets={setFileListOfAssets}
                  assetCategory = {props.catId}
                  />:subCategoryId === 16?
                   <ProductCatalogueDetailsFile
                   ASIN={ASIN}
                   integerID={props.storeOverview.integerID}
                   />
                  :
                    <OtherProductAssets
                    assetCategory = {props.catId}
                    assetIdentifier={ASIN}
                    subCategoryId={subCategoryId} 
                    fileListOfAssets={fileListOfAssets} 
                    setFileListOfAssets={setFileListOfAssets}
                    allFileListOfAssets={allFileListOfAssets}
                    setAllFileListOfAssets={setAllFileListOfAssets}
                    />}
                </LoadingOverlay>
              </Col>
            </>
          }
        </Row>

      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    UserPermission: state.accountOverview.store.StoreUserPermission,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};
export default connect(mapStateToProps)(ProductDetails);
